::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
    margin: 10px;
}

::-webkit-scrollbar-track {

    background: transparent;        /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
    background-color: rgb(50,99,88);    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
}