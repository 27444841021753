body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.react-calendar__navigation__label {
  background: transparent;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  font-size: 17px;
  color: black;
  margin-bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
}

.react-calendar__navigation__label:hover {
  cursor: pointer;
  background: #0B6258;
  color: white;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next-button {
  background-color: transparent;
  border: none;
  margin-bottom: 1em;
  font-size: 17.59px;
}

.react-calendar__navigation__next-button:hover {
  cursor: pointer;
  background-color: #EEEEEE;
  background: #0B6258;
  color: white;
}

.react-calendar__navigation__prev-button {
  background-color: transparent;
  border: none;
  margin-bottom: 1em;
  font-size: 17.59px;
}

.react-calendar__navigation__prev-button:hover {
  cursor: pointer;
  background-color: #EEEEEE;
  background: #0B6258;
  color: white;
}


/* ADDED */
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
 
/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */
 
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #0B6258;
  border-radius: 6px;
}
 
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background-color: #0B6258 !important;
}
 
.react-calendar__tile--active {
  background: #d4a713;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}
 
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #d4a713;
  color: white;
}
 
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
 
.react-calendar__tile--range {
  background-color: #0B6258 !important;
  color: white;
  border-radius: 0;
}
 
/* The first tile in the range */
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #0B6258 !important;
  color: white;
}
 /* The last tile in the range */
.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #0B6258 !important;
  color: white;
}

