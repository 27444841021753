ul.react-paginate {
  position: relative;
  margin-bottom: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style-type: none;
  width: 100%;

  @media screen and (max-width: 768px) {
    justify-content: inherit;
  }
}

ul.react-paginate li {
  margin-right: 10px;
}

ul.react-paginate li a {
  border-radius: 7px;
  padding: 0.5rem 1rem;
  border: gray 1px solid;
  cursor: pointer;
  pointer-events: none;
  width: 50%;

}

ul.react-paginate li.previous a,
ul.react-paginate li.next a,
ul.react-paginate li.break a {
  border-color: transparent;
  pointer-events: all;
  background-color: #0B6258;
  color: white;
}

ul.react-paginate li.selected a {
  background-color: #0B6258;
  border-color: transparent;
  color: white;
  min-width: 32px;
}

ul.react-paginate li.disabled a {
  color: grey;
}

ul.react-paginate li.disable,
ul.react-paginate li.disabled a {
  cursor: default;
}