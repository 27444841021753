.ol-zoom .ol-zoom-in {
    border-radius: 22px !important;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 40%) !important;
}

.ol-zoom .ol-zoom-out {
    border-radius: 22px !important;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 40%) !important;
}

.ol-attribution {
    display: none !important;
}

.ol-control {
    background-color: transparent !important;
    border-radius: 0;
}

.ol-control button {
    display: initial !important;
    margin: 1px 7.5px !important;
    padding: 0;
    /*color:  rgb(50,99,88) !important; */
    color:  rgb(228,78,60) !important;
    font-weight: bold;
    text-decoration: none;
    font-size: x-large !important;
    text-align: center;
    height: 44px !important;
    width: 44px !important;
    line-height: .4em;
    background-color: white !important;
    border: none;
    border-radius: 2px;
}

.ol-control button:hover {
    background-color: rgb(50,99,88) !important;
    color: white !important;
}







